/** This is controlling the led circle that is being litten up **/
.light {
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
}

/** this is the container that our led lives in **/
.led {
  background-color: #f8fbd9;
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  user-select: none;
}

.led.selected {
  /* background-color: #828380; */
  border: dashed 2px rgb(13, 13, 13);
}

.led.mode-erase {
  cursor: url('./assets/images/eraser.png'), auto;
}

.led.mode-paint {
  cursor: url('./assets/images/paint-roller.png'), auto;
}

.led.mode-select {
  cursor: url('./assets/images/selection.png'), auto;
}

.led.mode-unselect {
  cursor: url('./assets/images/unselect.png'), auto;
}

#leds {
  position: relative;
}

/** Strip controls a row of leds **/

.strip {
  display: flex;
}

.strip.right {
  flex-direction: row-reverse;
}

.strip.right-down {
  flex-direction: column;
}

.strip.left-down {
  flex-direction: column;
}

/** Player **/

.player input {
  width: 100%;
}

.controls {
  text-align: center;
}

.controls .btn {
  display: inline;
  border: solid 1px black !important;
  margin: 5px;
  width: fit-content;
}

/** Select **/
.form-btn {
  margin-top: 32px;
}

.spinner-image {
  display: block;
  margin: auto;
}

/** helpers **/

.relative {
  position: relative;
}

.clickable {
  cursor: pointer;
  font-weight: bold;
}

/** Navigation **/
span.nav-link {
  cursor: pointer;
  user-select: none;
}

a {
  user-select: none;
}

/** Context Menu **/
#content-menu {
  width: 100px;
  position: absolute;
  background-color: #efefef;
  list-style: none;
  padding: 0;
  margin: 0;
  /** Reddit feedback **/
  border: solid 1px gray;
  border-bottom: none;
  z-index: 100;
}

#content-menu li {
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid gray;
  padding: 10px;
}

#content-menu li:hover {
  background-color: #dddddd;
}

/** Navbar **/

.navbar-brand img {
  margin-right: 10px;
}
